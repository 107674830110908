* {
  -webkit-tap-highlight-color: transparent;
}

*::selection {
  background: rgb(34, 98, 124);
  color: white;
  border-radius: 5px;
  cursor: pointer !important;
}

.Polaris-TopBar__SearchField {
  width: auto;
  display: none;
}

button,
.Polaris-Button__Content,
.Polaris-Button__Text,
input::placeholder,
.Polaris-Navigation__Text,
.Polaris-ActionList__Content,
.Polaris-ActionList__Text,
.Polaris-Modal-Header__Title,
.Polaris-Modal-Header__Title .Polaris-DisplayText,
.Polaris-Label__Text,
.Polaris-TextField__Prefix,
.Polaris-TextField__Prefix span,
.Polaris-TextField__Prefix.Polaris-TextStyle--variationStrong,
.Polaris-Frame-ContextualSaveBar__Message,
.Polaris-Listbox-TextOption *,
.Polaris-OptionList__Title {
  user-select: none !important;
}

img,
svg,
text,
.Polaris-Thumbnail {
  user-select: none !important;
  color: inherit !important;
}

.productImageUploadHeight *,
.productImageUploadHeight {
  height: 100%;
}

.productImageUploadHeight *.Polaris-Stack,
.productImageUploadHeight *.Polaris-DropZone-FileUpload__Action {
  height: auto !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.liveBefore::before {
  animation: nYhkg 1.6s ease-in-out infinite;
  background-color: #d72c0d;
  border-radius: 50%;
  content: "";
  height: 5px;
  left: 0.5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -40%);
  width: 5px;
}

em-emoji-picker {
  position: absolute;
  z-index: 9;
}

.Polaris-DropZone--sizeSmall {
  min-height: inherit !important;
  margin-left: 0.5rem;
}

.Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: inherit !important;
}

.productImageOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.788);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 300ms;
}

.productImageBlock:hover .productImageOverlay {
  opacity: 1 !important;
}

.Ot7rf {
  border-left: 1px solid var(--p-divider);
  flex-basis: 25%;
  flex-grow: 1;
  padding-left: var(--p-space-4);
}

.Ot7rf:first-of-type {
  border-left: 0;
  padding-left: 0;
  padding-right: var(--p-space-4);
}

@media screen and (max-width: 489px) {
  .Ot7rf {
    border-left: 0;
    padding: var(--p-space-4);
  }
  .Ot7rf:first-of-type {
    padding-left: var(--p-space-4);
  }
  .Ot7rf:nth-of-type(2) {
    border-bottom: 1px solid var(--p-divider);
    border-top: 1px solid var(--p-divider);
  }
}

@keyframes nYhkg {
  0%,
  to {
    opacity: 0.3;
  }
  25%,
  75% {
    opacity: 1;
  }
}
